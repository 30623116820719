import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"
import Button from "../components/Button"

import EdlusLogo from "../images/edlus-logo.svg"
import DesignCirclePartImage from "../images/mask@3x.png"
import AppDevThumbnail from "../components/thumbnails/AppDevThumbnail"
import SoftDevThumbnail from "../components/thumbnails/SoftDevThumbnail"
import UiUxThumbnail from "../components/thumbnails/UiUxThumbnail"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  listImage,
  challengeTitle,
  challengeDescription,
  challengeAchievement,
  challengeImage,
  solutionTitle,
  solutionSubtitle,
  solutionDescription,
  solutionDescription2,
  solutionHighlight,
  solutionAdditionalInfo,
  solutionAdditionalInfoHighlight,
  solutionImage,
  solutionImage2,
  solutionImage3,
  solutionImage4,
  solutionImage5,
  resultTitle,
  resultDescription,
  resultItems,
  helmet,
}) => {
  return (
    <div>
      {helmet || ""}
      {/* FOLD BLOCK */}
      <div className="relative lg:flex container mx-auto py-12 pb-24 px-10 lg:px-32">
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-24 transform rotate-180"
          style={{ left: "40%", top: "5px" }}
        />
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-48"
          style={{ right: "6rem", bottom: "-3rem" }}
        />
        <div className="lg:w-1/3">
          <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none lg:pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-24">
            <Button to="https://edlus.lmt.lv/">edlus.lmt.lv</Button>
          </p>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-24">
            {mainDescription}
          </p>
          <p className="mt-10">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React Native</TechTag>
          </p>
        </div>
        <div className="lg:w-2/3 mt-10 lg:mt-0">
          <img
            src={listImage.childImageSharp.fixed.src}
            alt=""
            className="-mb-4"
          />
        </div>
      </div>

      {/* CHALLENGE BLOCK */}
      <div className="" style={{ backgroundColor: "#f2f2f2" }}>
        <div className="container mx-auto py-24 px-10 lg:px-32">
          <h2 className="font-semibold text-3xl mb-12">{challengeTitle}</h2>
          <div className="md:flex md:flex-row">
            <div className="md:w-1/3 md:pr-16 mb-10 md:mb-0">
              <img src={challengeImage.childImageSharp.fixed.src} alt="" />
            </div>
            <div className="md:w-2/3 text-gray-500 lg:pr-32">
              <p className="">{challengeDescription}</p>
              <p className="">{challengeAchievement}</p>
            </div>
          </div>
        </div>
      </div>

      {/* SOLUTION BLOCK */}
      <div className="container mx-auto py-12 pb-0 px-10 lg:px-32">
        <div className="md:flex flex-row">
          <div className="flex-1">
            <h2 className="font-semibold text-3xl mb-12">{solutionTitle}</h2>
            <p className="mb-10">
              <b>{solutionSubtitle}</b>
            </p>
            <p className="mb-4 text-gray-500">{solutionDescription}</p>
            <p className="text-gray-500">{solutionDescription2}</p>
          </div>
          <div className="relative flex-1">
            <img
              src={solutionImage.childImageSharp.fixed.src}
              alt=""
              className="md:absolute w-1/2"
              style={{ right: "5rem", top: "-7rem" }}
            />
            <img
              src={solutionImage2.childImageSharp.fixed.src}
              alt=""
              className="md:absolute w-1/2"
              style={{ left: "2rem", bottom: "-6rem" }}
            />
          </div>
        </div>

        <div className="md:flex flex-row my-32 mb-20">
          <div className="relative flex-1">
            <div
              style={{
                width: "305px",
                height: "305px",
                opacity: "0.3",
                border: "solid 40px #3aaf8e",
                borderRadius: "500px",
              }}
            ></div>
            <img
              src={solutionImage3.childImageSharp.fixed.src}
              alt=""
              className="absolute"
              style={{ left: "-1rem", top: "-3rem", width: "55%" }}
            />
            <img
              src={solutionImage4.childImageSharp.fixed.src}
              alt=""
              className="absolute"
              style={{ right: "9rem", bottom: "-7rem", width: "60%" }}
            />
          </div>
          <div className="relative flex-1 mt-32 md:mt-12">
            <p className="mb-12">
              <b>{solutionHighlight}</b>
            </p>
            <p className="text-gray-500">{solutionAdditionalInfo}</p>
          </div>
        </div>

        <div className="mt-20 flex flex-col">
          <div className="md:w-1/2 bg-gray-900 text-white p-10 self-end pb-20 -mb-10">
            {solutionAdditionalInfoHighlight}
          </div>
          <div className="">
            <img
              src={solutionImage5.childImageSharp.fixed.src}
              alt=""
              className="w-2/3 mx-auto -mb-8"
            />
          </div>
        </div>
      </div>

      {/* RESULT BLOCK */}
      <div className="" style={{ backgroundColor: "#f2f2f2" }}>
        <div className="relative container mx-auto py-24 px-10 lg:px-32">
          <img
            src={DesignCirclePartImage}
            alt=""
            className="absolute w-32"
            style={{ right: "11rem", bottom: "-4rem" }}
          />
          <h2 className="font-semibold text-3xl mb-12">{resultTitle}</h2>
          {false && (
            <ul className="mb-12 md:flex flex-row text-gray-500">
              {resultItems.map((item, i) => {
                const [number, ...words] = item.split(" ")
                const description = words.join(" ")
                return (
                  <li key={i} className="md:mr-12 md:w-1/4 mb-8 md:mb-0">
                    <div className="text-2xl md:mb-4 text-gray-900">
                      <b>{number}</b>
                    </div>
                    {description}
                  </li>
                )
              })}
            </ul>
          )}
          <div className="flex flex-row items-center">
            <img src={EdlusLogo} alt="" className="mr-12" />
            <h3 className="text-3xl font-bold">{resultDescription}</h3>
          </div>
        </div>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <div className="container mx-auto py-32 px-10 lg:px-32">
        <h3 className="text-center font-semibold text-2xl mb-12">
          <FormattedMessage id="case_study.tech_used" values={{ app: title }} />
        </h3>
        <div className="md:flex md:flex-row justify-center">
          <AppDevThumbnail className="md:w-1/3" />
          <SoftDevThumbnail className="md:w-1/3" />
          <UiUxThumbnail className="md:w-1/3" />
        </div>
      </div>
    </div>
  )
}

const LmtEdlusCaseStudy = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{`${project.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${project.frontmatter.mainDescription}`}
            />
          </Helmet>
        }
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default LmtEdlusCaseStudy

export const pageQuery = graphql`
  query edlusProjectByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        mainDescription
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        challengeTitle
        challengeDescription
        challengeAchievement
        challengeImage {
          childImageSharp {
            fixed(width: 600, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionTitle
        solutionSubtitle
        solutionDescription
        solutionDescription2
        solutionHighlight
        solutionAdditionalInfo
        solutionAdditionalInfoHighlight
        solutionImage {
          childImageSharp {
            fixed(width: 540, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionImage2 {
          childImageSharp {
            fixed(width: 540, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionImage3 {
          childImageSharp {
            fixed(width: 550, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionImage4 {
          childImageSharp {
            fixed(width: 550, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionImage5 {
          childImageSharp {
            fixed(width: 1400, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        resultTitle
        resultDescription
        resultItems
      }
    }
  }
`
