import React from "react"
import { Link } from "gatsby"

import "./Button.css"

const Button = ({ className, outlined, external, to, ...other }) => {
  let classes =
    "btn-default leading-none inline-block px-10 py-4 text-lg rounded-full font-semibold"
  if (outlined) {
    classes += ` border-2 hover:bg-green-100 text-green-500 hover:text-green-800 border-green-500 hover:border-green-800`
  } else {
    classes += ` text-white bg-green-500 hover:bg-green-800`
  }
  classes += ` ${className}`

  if (/^https?:/.test(to)) {
    return <a className={classes} href={to} {...other} />
  } else {
    return <Link className={classes} to={to} {...other} />
  }
}

export default Button
